<template>
  <main class="main page glossary CMS_pages">
    <div class="heroBanner heroBannerBGcolor">
      <h1 class="section__heading">Glossary</h1>
    </div>
    <section class="section sectionContiner" id="glossary">
      <!-- <h1 class="section__heading">WTF Glossary</h1> -->
      <div v-if="showNavigation" class="glossary__navigation">
        <a
          v-for="(group, i) in glossary"
          :key="`glossary-index-${i}`"
          :href="`#glossary-group-${i}`"
          :class="{ disabled: !group.items || group.items.length <= 0 }"
        >
          {{ group.name }}
        </a>
      </div>
      <div class="text glossary__container">
        <!-- To restore original behavior, remove 'glossary-group' container and change template loop to div -->
        <div class="glossary-group">
          <template
            v-for="(group, i) in glossary"
            :key="`group-${i}`"
            class="glossary-group"
            :id="`glossary-group-${i}`"
          >
            <template v-if="group.items && group.items.length > 0">
              <!-- <h1 v-if="group.name">{{ group.name }}</h1> --> <!-- Save -->
              <div
                v-for="(item, j) in group.items"
                :key="`item-${i}-${j}`"
                class="glossary-group__item"
              >
                <img
                  v-if="item.image"
                  :src="require(`@/assets/images/glossary/${item.image}`)"
                  :alt="item.name"
                >
                <h2 v-if="item.name" class="glossary-heading">
                  {{ item.name }}
                </h2>
                <p v-if="item.description">{{ item.description }}</p>
              </div>
            </template>
          </template>
        </div>
      </div>
    </section>

  </main>
</template>

<script>
export default {
  data () {
    return {
      showNavigation: false,
      glossary: [
        {
          name: 'A-B',
          items: [
            {
              name: 'Area Rug',
              image: 'area-rug.jpg',
              description: 'A rug that only covers part of a floor. Area rugs generally range in size from 3\' × 5\' to 9\' × 12\'.'
            },
            {
              name: 'Armoire/Wardrobe',
              image: 'armoire-wardrobe.jpg',
              description: 'A tall, freestanding closet that often includes drawers or shelves.'
            }
          ]
        },
        {
          name: 'C-D',
          items: [
            {
              name: 'Club Chair',
              image: 'club-chair.jpg',
              description: 'A deep chair, traditionally leather, that features a low back with wider sides and arms.'
            },
            {
              name: 'Chaise Lounge',
              image: 'chaise-lounge.jpg',
              description: 'French for “long chair,” a chaise has different uses – as a stand-alone piece (top image) or attached to a sofa (creating the “L” shape, bottom image).'
            },
            {
              name: 'Counter Stool/Bar Stool',
              image: 'counter-bar-stool.jpg',
              description: 'Counter stools are generally a bit shorter (between 24" - 26" tall) than bar stools (between 28" - 30" tall). Both are used for casual seating.'
            },
            {
              name: 'Daybed',
              image: 'daybed.jpg',
              description: 'A multi-functional piece of furniture that can be used for seating, lounging, reclining or sleeping.'
            },
            {
              name: 'Dinette Table',
              image: 'dinette-table.jpg',
              description: 'A small dining table that accommodates 1-3 people.'
            },
            {
              name: 'Drop Leaf Table',
              image: 'drop-leaf-table.jpg',
              description: 'A table featuring folding extensions (top) or inserts (bottom) that are used to expand or shrink the size of the dining surface.'
            }
          ]
        },
        { name: 'E-F' },
        { name: 'G-H' },
        { name: 'I-J' },
        {
          name: 'K-L',
          items: [
            {
              name: 'Love Seat',
              image: 'loveseat.jpg',
              description: 'A small sofa that seats two people. toTree loveseats range between 54-65" wide.'
            }
          ]
        },
        {
          name: 'M-N',
          items: [
            {
              name: 'Media Console',
              image: 'media-console.jpg',
              description: 'Furniture used for storage and housing electronics (TV, cable boxes, gaming consoles, etc). They’re typically lower than sideboards and often feature open air shelving.'
            },
            {
              name: 'Modular Sofa',
              image: 'modular-sofa.jpg',
              description: 'A sofa comprised of movable sections called modules, allowing for greater versatility and design in living spaces.'
            },
            {
              name: 'Nightstand',
              image: 'nightstand.jpg',
              description: 'A small table or cabinet that stands beside a bed.'
            }
          ]
        },
        {
          name: 'O-P',
          items: [
            {
              name: 'Ottoman',
              image: 'ottoman.jpg',
              description: 'An easily moveable piece of furniture that can serve as a footrest, a table stool, or an addition to a chair or sofa.'
            }
          ]
        },
        { name: 'Q-R' },
        {
          name: 'S-T',
          items: [
            {
              name: 'Sectional',
              image: 'sectional.jpg',
              description: 'A large sofa with two or more joined sections. The three most common configurations are Chaise sectional (top), L-shaped sectional (middle) and U-shaped sectional (bottom).'
            },
            {
              name: 'Side Table',
              image: 'side-table.jpg',
              description: 'A small table placed at the side of a sofa or chair.'
            },
            {
              name: 'Sideboard/Credenza',
              image: 'sideboard.jpg',
              description: 'A long, low, flat-topped piece of furniture with shelves and/or drawers. Used primarily for storage, they are typically found in dining and living rooms.'
            },
            {
              name: 'Sleeper Sofa',
              image: 'sleeper-sofa.jpg',
              description: 'A sofa that converts into a bed, via a built-in mattress or other padding.'
            },
            {
              name: 'Standing Desk',
              image: 'standing-desk.jpg',
              description: 'A desk designed for use while standing up. Many are adjustable and can be used while sitting or standing.'
            },
            {
              name: 'Storage Bench/Ottoman',
              image: 'storage-ottoman.jpg',
              description: 'An ottoman or bench with a hinged top, ideal for extra storage space.'
            },
            {
              name: 'Tufting',
              image: 'tufting-detail.jpg',
              description: 'Small stiches or buttons on an upholstered piece of furniture to create dimension.'
            }
          ]
        },
        { name: 'U-V' },
        { name: 'W-Z' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.section__subtitle {
  font-family: 'GT America Expanded';
  grid-column: span 12;
  text-align: center;
  margin-bottom: var(--gutter);
}
.glossary {
  &__navigation {
    margin-bottom: var(--gutter);
    grid-column: span 12;
    text-align: center;

    a {
      display: inline-block;
      margin: 0 calc(var(--gutter) * 0.5);

      &.disabled {
        pointer-events: none;
        color: transparentize($tt-walnut, .8);
      }
    }
  }
  &__container {
    // grid-column-start: 3 !important;
    // grid-column-end: 11 !important;
    padding: calc(var(--gutter) * 2);
    grid-column: span 12 !important;
    // @include breakpoint($m-down) {
    //   grid-column: span 12 !important;
    // }
    @include breakpoint($s) {
      padding: var(--gutter);
    }
  }

  // .glossary-heading {
  //   font-family: 'GT America Regular';
  //   text-transform: uppercase;
  // }

  .glossary-group {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 var(--gutter);

    @include breakpoint($s) {
      display: block;
    }

    h1 {
      grid-column: span 12;
      margin: 0 0 calc(var(--leading-h1) * 1);
    }
    &__item {
      grid-column: span 4;
      margin-bottom: calc(var(--gutter) * 2);
      h2{
        font-size: 20px;
        font-family: 'Sohne';
        color: #0B1326;
      }
      p{
        font-size: 16px; color: #0B1326;
      }
    }
  }
}
.glossary-image {
  padding-right: 4rem;
  padding-left: 4rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

.glossary{
  .sectionContiner {
  padding-bottom: 0 !important;
  .glossary__container {
    padding-bottom: 0;
  }
}
}

</style>
